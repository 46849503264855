import type { ReactNode } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { ChevronUpIc, ChevronDownIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';

interface OverflowFadeProps {
  mobileContainerMaxHeight: number;
  desktopContainerMaxHeight?: number;
  isContentOverflowing?: boolean;
  children: ReactNode;
  overflowText?: string;
  seeLessText?: string;
  hasChevron?: boolean;
}
type ContainerStyleProps = {
  isTextExpanded: boolean;
  mobileContainerMaxHeight: number;
  desktopContainerMaxHeight?: number;
};
const Container = styled.div<ContainerStyleProps>`
  ${({ theme }) => theme.fontSize.M14};
  position: relative;
  overflow: auto;
  overflow-y: hidden;
  height: auto;

  ${({ isTextExpanded, mobileContainerMaxHeight }) =>
    isTextExpanded
      ? `
    ''
  `
      : `max-height: ${mobileContainerMaxHeight}px;`}

  ${({ isTextExpanded, desktopContainerMaxHeight }) =>
    desktopContainerMaxHeight &&
    (isTextExpanded
      ? `
        ''
      `
      : `${media.large} {max-height: ${desktopContainerMaxHeight}px;}`)}
`;
type OverflowFadeUnitStyleProps = {
  isTextExpanded: boolean;
};
const OverflowFadeUnit = styled.div<OverflowFadeUnitStyleProps>`
  width: 100%;
  cursor: pointer;

  ${({ isTextExpanded, theme }) =>
    isTextExpanded
      ? 'position: relative'
      : `background: linear-gradient(180deg, ${theme.colors.WHITE} 0%, ${theme.colors.WHITE}00 0.01%, ${theme.colors.WHITE} 90%); 
        padding-top: 72px;
        position: absolute;
        bottom: 0;`}
`;

type TOverflowText = { hasChevron: boolean };
const OverflowText = styled(LinkButton)<TOverflowText>`
  margin-right: ${({ theme, hasChevron }) =>
    hasChevron ? theme.spacing.S4 : '0'};
`;

function OverflowFade({
  children,
  mobileContainerMaxHeight,
  desktopContainerMaxHeight,
  isContentOverflowing = true,
  overflowText = 'Read More',
  seeLessText = 'Read Less',
  hasChevron = false,
}: OverflowFadeProps) {
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  function handleTextExpansion() {
    setIsTextExpanded(!isTextExpanded);
  }

  return (
    <Container
      isTextExpanded={isTextExpanded}
      mobileContainerMaxHeight={mobileContainerMaxHeight}
      desktopContainerMaxHeight={desktopContainerMaxHeight}
    >
      {children}
      {isContentOverflowing && (
        <OverflowFadeUnit
          data-testid="overflow-fade-element"
          isTextExpanded={isTextExpanded}
          onClick={handleTextExpansion}
        >
          <OverflowText
            hasChevron={hasChevron}
            ofType="SECONDARY"
            underline
            fontSize="M14"
            data-testid="overflow-fade-text"
          >
            {isTextExpanded ? seeLessText : overflowText}
            {hasChevron && (
              <>
                {isTextExpanded ? (
                  <ChevronUpIc width={16} height={16} />
                ) : (
                  <ChevronDownIc width={16} height={16} />
                )}
              </>
            )}
          </OverflowText>
        </OverflowFadeUnit>
      )}
    </Container>
  );
}

export { OverflowFade };
