import { IAdSenseUnit } from 'types';

interface AdSenseConfig {
  [key: string]: IAdSenseUnit;
}

export const AD_DETAILS_AD_SENSE_CONFIG: AdSenseConfig = {
  middle: {
    container: 'csa_dd_detail_mid',
    number: 2,
    detailedAttribution: false,
  },
};

export const SEARCH_PAGE_AD_SENSE_CONFIG: AdSenseConfig = {
  bottom: {
    container: 'btm-placement',
    number: 3,
    lines: 3,
  },
  listing: {
    container: 'dd_search_10',
    number: 2,
    lines: 2,
  },
};
