import { IncomingHttpHeaders } from 'http';
import getConfig from 'next/config';
import type { AxiosResponse } from 'axios';

import { donedealGateway } from 'api/api-utils/commonApiUtils';
import {
  AxiosResponseWithTimer,
  withDuration,
  withMinDuration,
} from 'api/api-utils/middleware';

import type {
  ISavedSearch,
  IPreCheckResponse,
  ISearchRequest,
} from 'api/types/savedSearchApiTypes';
import type { TCreateSavedSearchFromAd } from 'api/types/searchApiTypes';
import type { SavedSearch } from 'types';

const {
  publicRuntimeConfig: { API_LISTINGS_TIMEOUT },
} = getConfig();

async function createSavedSearch(
  data: ISavedSearch,
  userId: string,
  minTime: number,
  headers: IncomingHttpHeaders,
  timeout?: number,
): Promise<AxiosResponseWithTimer<SavedSearch>> {
  const response: AxiosResponseWithTimer<SavedSearch> = await withMinDuration(
    withDuration(donedealGateway),
    minTime,
  ).post(
    `/ddapi/v1/users/savedsearches`,
    {
      ...data,
      startTime: new Date().getTime(),
    },
    {
      headers: {
        ...headers,
        accept: 'application/json',
        'X-Identity-UserId': userId,
      },
      timeout: timeout || parseInt(API_LISTINGS_TIMEOUT),
    },
  );
  return response;
}

const createSavedSearchFromAd: TCreateSavedSearchFromAd<
  Promise<AxiosResponseWithTimer<ISavedSearch>>
> = async (data, userId, adId, minTime, headers) => {
  const response: AxiosResponseWithTimer<ISavedSearch> = await withMinDuration(
    withDuration(donedealGateway),
    minTime,
  ).post(`/ddapi/v1/users/savedsearches/ad/${adId}`, data, {
    headers: {
      accept: 'application/json',
      'X-Identity-UserId': userId,
      ...headers,
    },
    timeout: parseInt(API_LISTINGS_TIMEOUT),
  });
  return response;
};

async function getSavedSearches(
  args: {
    userId: string;
    page: number;
    offset: number;
  },
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<ISavedSearch[]>> {
  const { userId, page, offset } = args;
  const response: AxiosResponse<ISavedSearch[]> = await donedealGateway.get(
    `/ddapi/v1/users/savedsearches?page=${page}&size=${offset}`,
    {
      headers: {
        ...headers,
        'X-Identity-UserId': userId,
      },
    },
  );
  return response;
}

async function deleteSavedSearch(
  id: number,
  userId: string,
  minTime: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponseWithTimer<string>> {
  const response: AxiosResponseWithTimer<string> = await withMinDuration(
    withDuration(donedealGateway),
    minTime,
  ).delete(`/ddapi/v1/users/savedsearches/${id}`, {
    headers: {
      'X-Identity-UserId': userId,
      ...headers,
    },
    timeout: parseInt(API_LISTINGS_TIMEOUT),
  });
  return response;
}

async function updateSearchAlert(
  adId: number,
  userId: string,
  payload: Pick<ISavedSearch, 'name' | 'description' | 'frequency' | 'search'>,
  minTime: number,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
): Promise<AxiosResponseWithTimer<null>> {
  const response: AxiosResponseWithTimer<null> = await withMinDuration(
    withDuration(donedealGateway),
    minTime,
  ).put(`/ddapi/v1/users/savedsearches/${adId}`, payload, {
    headers: {
      ...headers,
      'X-Identity-UserId': userId,
      timeout: parseInt(API_LISTINGS_TIMEOUT),
    },
  });
  return response;
}

async function searchPagePreCheck(
  userId: string,
  payload: ISearchRequest,
  headers: IncomingHttpHeaders,
  timeout?: number,
): Promise<AxiosResponse<IPreCheckResponse>> {
  const response: AxiosResponse<IPreCheckResponse> = await donedealGateway.post(
    `/ddapi/v1/users/savedsearches/precheck`,
    {
      ...payload,
      startTime: new Date().getTime(),
    },
    {
      headers: {
        ...headers,
        'X-Identity-UserId': userId,
      },
      timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
    },
  );
  return response;
}

async function adDetailsPagePreCheck(
  adId: number,
  userId: number,
  header: IncomingHttpHeaders,
): Promise<AxiosResponse<IPreCheckResponse>> {
  const response: AxiosResponse<IPreCheckResponse> = await donedealGateway.post(
    `/ddapi/v1/users/savedsearches/ad/${adId}/precheck`,
    null,
    {
      headers: {
        'X-Identity-UserId': userId,
        ...header,
      },
    },
  );
  return response;
}

export const savedSearchApi = {
  createSavedSearch,
  createSavedSearchFromAd,
  deleteSavedSearch,
  getSavedSearches,
  updateSearchAlert,
  searchPagePreCheck,
  adDetailsPagePreCheck,
};
